<template>
  <div class="default-bg-loading">
    <SquareGrid class="square-grid-style"></SquareGrid>
  </div>
</template>
<script>
import { SquareGrid } from 'vue-loading-spinner';

export default {
  name: 'Loading',
  components: { SquareGrid },
  props: {
    value: [Array, Boolean],
    onText: String,
    offText: String,
  },
};
</script>
<style>
.sk-cube {
  background-color: white !important;
}
.square-grid-style {
  width: 40px;
  height: 40px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999999;
}
.default-bg-loading {
  transition: brackground-color 2s linear;
  height: 100%;
  position: fixed;
  width: 100vw;
  top: 0px;
  background-color: #1c1c1cd1;
  z-index: 999999999;
}
</style>
