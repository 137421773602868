<template>
  <div>
    <div
      class="page-header clear-filter"
      style="min-height: 330px"
      filter-color="orange"
    >
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg5.jpg')"
      >
      </parallax>
      <div class="container">
        <!-- <div class="photo-container">
          <img src="img/ryan.jpg" alt="" />
        </div> -->
        <h3 class="title">Trabalhos realizados</h3>
        <div class="social-description">
          <h2>{{ pagination.totalCount }}</h2>
        </div>
        <!-- <div class="content">
          <div class="social-description">
            <h2>26</h2>
            <p>Comments</p>
          </div>
          <div class="social-description">
            <h2>26</h2>
            <p>Comments</p>
          </div>
          <div class="social-description">
            <h2>48</h2>
            <p>Bookmarks</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="section">
      <div class="container">
        <!-- <div class="button-container">
          <a href="#button" class="btn btn-primary btn-round btn-lg">Follow</a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Twitter"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Instagram"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div> -->
        <!-- <div id="carousel">
          <div class="container">
            <div style="text-align: center">
              <h4>Últimos trampos</h4>
            </div>
            <div class="row justify-content-center">
              <div class="col-12">
                <el-carousel height="500px">
                  <el-carousel-item>
                    <img class="d-block" src="img/bg1.jpg" alt="First slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5>Nature, United States</h5>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <img class="d-block" src="img/bg3.jpg" alt="Second slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5>Somewhere Beyond, United States</h5>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <img class="d-block" src="img/bg4.jpg" alt="Third slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5>Yellowstone National Park, United States</h5>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <button
              @click="modal.classic = true"
              class="btn btn-primary btn-round btn-lg btn-block"
              type="button"
            >
              <span> Cadastrar um novo trabalho </span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <el-table :data="jobs">
              <el-table-column min-width="50" type="index"></el-table-column>
              <el-table-column
                min-width="150"
                prop="title"
                label="Título"
              ></el-table-column>
              <el-table-column
                min-width="200"
                prop="description"
                label="Descrição"
              ></el-table-column>
              <el-table-column
                min-width="150"
                prop="createdAt"
                label="Data"
              ></el-table-column>
              <el-table-column
                min-width="150"
                prop-align="imagePath"
                label="Imagens"
              ></el-table-column>
              <el-table-column
                min-width="150"
                header-align="right"
                label="Ações"
              >
              <template slot-scope="{row}">
                <div class="text-right table-actions">
                  <!-- <el-tooltip
                    content="Editar"
                    :open-delay="300"
                    placement="top"
                  >
                    <n-button type="default" size="sm" icon>
                      <i class="now-ui-icons ui-2_settings-90"></i>
                    </n-button>
                  </el-tooltip> -->
                  <el-tooltip
                    content="Excluir"
                    :open-delay="300"
                    placement="top"
                  >
                    <n-button @click="deletePost(row._id)" type="danger" size="sm" icon>
                      <i class="now-ui-icons files_box"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row">
           <div class="col-12">
            <n-pagination
              style="justify-content: center;margin: 20px;"
              type="primary"
              :page-count="pagination.pages"
              v-model="pagination.currentPage"
              @input="updatePage"
            >
            </n-pagination>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modal.classic" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">Cadastro</h4>
      <div class="container">
        <div class="cold-md-12">
          <fg-input
              v-model="form.title"
              class="no-border input-lg"
              addon-left-icon="now-ui-icons users_circle-08"
              placeholder="Digite um título..."
              type="text"
            >
            </fg-input>
        </div>
        <div class="textarea-container col-md-12">
          <textarea
            style="max-height: 65px;"
            class="form-control"
            name="name"
            rows="4"
            cols="80"
            v-model="form.description"
            placeholder="Descrição..."
          ></textarea>
        </div>
      </div>
      <div v-if="form.images.length <= 0" class="col-md-6" style="margin: 0 auto;padding: 19px;">
          <uploadcare
          :tabs="'file camera'"
          :localeTranslations="'pt'"
          :multiple="true"
          :locale="'pt'"
          :publicKey="'7a140aa5d7a776ec19fd'"
          @success="onSuccess"
          @error="onError"
        >
          <n-button type="default">Escolher imagens</n-button>
          <!-- <button style="background-color: black;color: white;border-radius: 10px;border: none;padding: 11px;">Enviar fotos</button> -->
        </uploadcare>
      </div>
      <template v-if="form.images.length > 0" slot="footer">
        <!-- <n-button type="default" @click="confirmAndCreate">Confirmar</n-button> -->
        <n-button style="margin: auto;" type="default" @click="confirmAndCreate">Confirmar</n-button>
      </template>
    </modal>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
const UPLOADCARE_LOCALE = 'pt';
import { Button, Modal, Pagination, Loading, FormGroupInput } from '@/components';
import Uploadcare from 'uploadcare-vue';
import { jobService } from '@/_services';
import { Table, TableColumn, Tooltip } from 'element-ui';
export default {
  name: 'admin-home',
  bodyClass: 'profile-page',
  components: {
    // [Carousel.name]: Carousel,
    // [CarouselItem.name]: CarouselItem,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    [FormGroupInput.name]: FormGroupInput,
    Uploadcare,
    Loading
  },
  mounted() {
    this.getJobs()
  },
  computed: {
    user() {
      const footer = this.$store.getters['account/getUser'];
      return footer;
    },
  },
  methods: {
    async deletePost(id) {
      this.isLoading = true;
      await jobService.deletePost({
        id
      });
      this.isLoading = false;
      this.updatePage()
    },
    async getJobs () {
      this.isLoading = true;
      const res = await jobService.getAll({ page: this.pagination.currentPage, limit: this.pagination.limit });
      console.log('res', res)
      if (res && res.docs) {
        this.jobs = res.docs;
        this.jobs.forEach(item => {
          const date = new Date(item.createdAt)
          item.createdAt = date.toLocaleDateString()
        });
        this.pagination.totalCount = res.total;
        this.pagination.pages = res.pages;
      }
      this.isLoading = false;
    },
    updatePage () {
      this.form.images = []
      this.form.title = ''
      this.form.description = ''
      this.getJobs()
    },
    async confirmAndCreate() {
      this.modal.classic = false
      this.isLoading = true;
      await jobService.create({
        description: this.form.description,
        title: this.form.title,
        images: this.form.images,
      });
      this.isLoading = false;
      this.updatePage()
    },
    onSuccess(e) {
      this.form.images.push(e.cdnUrl);
      console.log('success', e);
    },
    onError(e) {
      console.log('Error', e);
    },
  },
  data() {
    return {
      isLoading: false,
      jobs: [],
      pagination: {
        totalCount: 0,
        pages: 0,
        limit: 5,
        currentPage: 1,
      },
      modal: {
        classic: false,
      },
      form: {
        description: '',
        title: '',
        images: [],
      },
      tableData: [
        {
          id: 1,
          name: 'Dakota Rice',
          salary: '$36.738',
          country: 'Niger',
          city: 'Oud-Turnhout',
        },
        {
          id: 2,
          name: 'Minerva Hooper',
          salary: '$23,789',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
        },
        {
          id: 3,
          name: 'Sage Rodriguez',
          salary: '$56,142',
          country: 'Netherlands',
          city: 'Baileux',
        },
        {
          id: 4,
          name: 'Philip Chaney',
          salary: '$38,735',
          country: 'Korea, South',
          city: 'Overland Park',
        },
        {
          id: 5,
          name: 'Doris Greene',
          salary: '$63,542',
          country: 'Malawi',
          city: 'Feldkirchen in Kärnten',
        },
      ],
    };
  },
};
</script>
<style>
.uploadcare--widget__button.uploadcare--widget__button_type_open {
  background-color: #000000;
}
</style>
