import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

const login = async body => {
    const userUrl = API_URL + 'auth/login'
    return await callApiService.callApiWithoutAuth(userUrl, { type: 'post', body })
}

async function signup (account) {
  return callApiService.callApiWithoutAuth(API_URL + 'accountOwners', account)
}

function resetpass (body, token) {
  let url = API_URL + 'passwordResetRequests'
  const type = token ? 'put' : 'post'
  url = token ? `${url}/${token}` : url
  return callApiService.callApiWithoutAuth(url, body, type)
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem('user')
  window.location.reload('/')
}

export const userService = {
  login, signup, resetpass, logout
}