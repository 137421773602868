import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import account from './modules/account'
Vue.use(Vuex)

const store = {
  modules: {
    account,
  },
  plugins: [
    createPersistedState({
      key: 'almAccount',
      paths: ['account']
    }),
  ]
}
export default new Vuex.Store(store)
