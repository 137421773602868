<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/admin">
        {{ $route.meta.title }}
      </router-link>
    </template>
    <template slot="navbar-menu">
         <!-- <drop-down
              tag="li"
              title="Serviços"
              icon="now-ui-icons education_paper"
              class="nav-item"
      >
        <nav-link to="">
          <i class="now-ui-icons ui-1_check"></i> Serviços realizados
        </nav-link>
      </drop-down> -->
      <li class="nav-item">
        <a
            href="#/"
          class="nav-link"
        >
          <i class="now-ui-icons users_circle-08"></i>
          <p>Página principal</p>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a
            href="#/admin"
          class="nav-link"
        >
          <i class="now-ui-icons users_circle-08"></i>
          <p>Administrativo</p>
        </a>
      </li> -->
      <!-- <li class="nav-item">
        <a
          href="#/jobs"
          class="nav-link"
        >
          <i class="now-ui-icons users_circle-08"></i>
          <p>Serviços realizados</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a
        style="cursor: pointer;"
        @click="doLogout"
          class="nav-link"
        >
          <i class="now-ui-icons users_circle-08"></i>
          <p>Sair</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components';
import { mapActions } from 'vuex'
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    // DropDown,
    Navbar,
    // NavLink,
    [Popover.name]: Popover
  },
  computed: {
    user () {
      const footer = this.$store.getters['account/getUser']
      return footer
    },
  },
  methods: {
      ...mapActions('account', ['logout']),
      doLogout () {
        this.logout()
        this.$router.go('/login')
      }
  }
};
</script>

<style scoped>
.popover-body {
  text-align: center;
}  
</style>
