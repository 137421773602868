
  function initialState () {
    return {
      loggedIn: false,
      user: {},
      account: {}
    }
  }
  const state = initialState
  
  const getters = {
    getUser: state => state.user,
    getAccount: state => state.user.account,
    getLang: state => state.lang,
    getExceededLimitMessage: state => state.exceededLimitMessage
  }
  
  const mutations = {
    loginSuccess (state, payload) {
      state.user = payload
    },
    loginFailure (state) {
      state.status = {}
    },
    setUser (state, user) {
      state.user = user
    },
    setAccount (state, account) {
      state.account = account
    },
  }
  
  const actions = {
    logout () {
        localStorage.removeItem('almAccount')
      },
  }
  
  export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
  }
  