import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

const create = async body => {
    const url = API_URL + 'posts/create'
    return await callApiService.callApi(url, { type: 'post', body })
}

const getAll = async (params = {}) => {
  const url = `${API_URL}posts/list?page=${params.page}&limit=${params.limit}`
    return await callApiService.callApi(url, {})
}

const getBanners = async (params = { page: 0, limit: 30 }) => {
  const url = `${API_URL}posts/banner?page=${params.page}&limit=${params.limit}`
    return await callApiService.callApi(url, {})
}

const deletePost = async body => {
  const url = API_URL + 'posts/' + body.id
  return await callApiService.callApi(url, { type: 'delete', body })
}

export const jobService = {
  create, getAll, getBanners, deletePost
}