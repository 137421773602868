import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import AdminIndex from './pages/Admin/Index.vue';
// import AdminJobs from './pages/Admin/Jobs.vue';
import Landing from './pages/Landing.vue';
import Login from './pages/Login.vue';
import Profile from './pages/Profile.vue';
import MainNavbar from './layout/MainNavbar.vue';
import AdminMainNavbar from './layout/admin/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import Store from '@/store/'

Vue.use(Router);

export const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/landing',
      name: 'landing',
      components: { default: Landing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      },
    },
    {
      path: '/profile',
      name: 'profile',
      components: { default: Profile, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/admin',
      name: 'admin-home',
      meta: {
        title: 'Administrativo'
      },
      components: {
        default: AdminIndex,
        header: AdminMainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    // {
    //   path: '/jobs',
    //   name: 'admin-jobs',
    //   meta: {
    //     title: 'Trabalhos'
    //   },
    //   components: {
    //     default: AdminJobs,
    //     header: AdminMainNavbar,
    //     footer: MainFooter,
    //   },
    //   props: {
    //     header: { colorOnScroll: 400 },
    //     footer: { backgroundColor: 'black' },
    //   },
    // },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login', '/landing']
  const authRequired = !publicPages.includes(to.path)
  let getUser = {}
  if (Store.state && Store.state.account && localStorage.getItem('almeidaUser')) {
    getUser = Store.state.account.user
  }
  if (authRequired && !getUser._id) {
    return next('/login')
  }
  if (getUser._id && authRequired) {
    // if (!to.meta.roles.includes(getUser.type)) {
    //   return next('/you-cant')
    // }
  }
  next()
})