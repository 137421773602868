export const callApiService = {
    callApi,
    callApiWithoutAuth,
    oldcallApi
  }
  
  function insertToken () {
    const token = JSON.parse(localStorage.getItem('almeidaUser'))
    if (token) {
      return 'Bearer ' + token
    }
    logout()
    throw new Error('Invalid token')
  }
  
  function logout () {
    localStorage.removeItem('almeidaUser')
    window.location.replace('#/login')
  }
  
  /**
   * Call API project
   *
   * @param {String} url contains ,the url of requisition
   * @param {String} method contains the method of requisition
   * @param {Object | Null} body contains the body of requisition
   * @param {Integer | Null} newRangeEnd contains the <end-range> for the Range request header || null.
   * @param {Integer | Null} newRangeStart contains the <start-range> for the Range request header || null.
   * @returns {Object}
   */
  
  async function oldcallApi (url, method, body, newRangeEnd, isFile, newRangeStart) {
    const headers = {
      Authorization: () => 'Bearer ' + localStorage.getItem('almeidaUser'),
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
    if (newRangeEnd) {
      if (!newRangeStart) {
        headers.Range = 'items=0-' + newRangeEnd
      } else {
        headers.Range = `items=${newRangeStart}-${newRangeEnd}`
      }
    }
    headers.Authorization = insertToken()
    const request = {
      method,
      headers
    }
    if (isFile) {
      request.headers = { Authorization: headers.Authorization }
    }
  
    if (method !== 'get' && body !== '') {
      request.body = isFile ? body : JSON.stringify(body)
    }
    try {
      const result = await fetch(
        url, request
      ).then(function (r) {
        if (r && [200, 201, 206].indexOf(r.status) !== -1) {
          const range = r.headers.get('Content-Range')
          const conditions = [
            'sending-responses',
            'sendings'
          ]
          const canReturnNewRangeObject = conditions.some(el => url.includes(el))
          if (newRangeEnd !== undefined && range && canReturnNewRangeObject) {
            return { data: r.json(), range }
          }
          return r.json()
        } else if (r.status === 401) {
          logout()
        }
      })
      return result
    } catch (error) {
      console.log('error at callApi.service', error)
    }
  }

  async function callApi (url, options) {
    try {
      const headers = {
        'x-access-token': localStorage.getItem('almeidaUser'),
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      return await window.fetch(url, {
        method: options.body ? options.type : 'get',
        headers: headers,
        ...(options.body && { body: JSON.stringify(options.body) })
      }).then(res => {
        if (res.status === 401) {
          logout()
        }
        return res.json()
      }).then(jsonResponse => {
        console.log('jsonResponse')
        return jsonResponse
      }).catch(error => {
        console.log('Error inside userService', error)
      })
    } catch (error) {
      console.log('Error to call DB on userService', error)
      return []
    }
  }
  
  async function callApiWithoutAuth (url, options) {
    try {
      const headers = {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      return await window.fetch(url, {
        method: options.body ? options.type : 'get',
        headers: headers,
        ...(options.body && { body: JSON.stringify(options.body) })
      }).then(res => {
        return res.json()
      }).then(jsonResponse => {
        console.log('jsonResponse')
        return jsonResponse
      }).catch(error => {
        console.log('Error inside userService', error)
      })
    } catch (error) {
      console.log('Error to call DB on userService', error)
      return []
    }
  }